// global jQuery, ga

/**
 * Google Analytics event tracking
 *
 * @param {string} category
 * @param {string} action
 * @param {string} label
 *
 * @returns {Utilities}
 */
function trackAnalyticEvent (category, action, label) {
    'use strict';

    ga('send', 'event', category, action, label, {
        'page'           : decodeURI(window.location),
        'nonInteraction' : 1
    });

    return this;
}

/* jQuery */
(function ($) {
    'use strict';

    /**
     * Glossary Accordions
     */
    const $glossaryTitles = $('.js-glossary-title');

    $glossaryTitles.on('click', function () {
        $(this).closest('.js-glossary-term').toggleClass('is-open').find('.js-glossary-text').slideToggle(300);
    });

    /**
     * Conclusion Accordions
     */
    const $accordionWrapper = $('.js-accordion');
    const $accordionTitle   = $('.js-accordion-title');
    const $accordionContent = $('.js-accordion-content');

    $accordionTitle.on('click', function () {
        $('.js-tooltip__tooltip').remove();

        $(this).closest('.js-accordion').toggleClass('is-open').find($accordionContent).slideToggle(300);
    });

    $accordionWrapper.on('keyup', function (e) {
        if (e.which == 13) {
            $(this).find($accordionTitle).click();
        }
    });

    // The tabindex of 3 is to accomodate for the 'Start Again' and 'Back' buttons,
    // which have tabindexes of 1 and 2 set.
    $accordionWrapper.each(function (i) {
        $(this).find('.js-accordion-title, .js-accordion-content a').attr('tabindex', i + 3);
    });

    /**
     * Tooltip functionality
     */
    const $tooltipBtns = $('.js-tooltip');

    $tooltipBtns.on('click', function (e) {
        e.stopPropagation();

        $(this).toggleClass('tooltip-open');

        if ($(this).next('.js-tooltip__tooltip').length > 0) {
            $(this).next('.js-tooltip__tooltip').fadeOut(300, 'linear', function () {
                $(this).remove();
            });
        } else {
            const tooltipText    = $(this).data('tooltip');
            const tooltipTop     = Math.round($(this).offset().top + $(this).outerHeight());
            const tooltipCenter  = Math.round($(this).offset().left + ($(this).outerWidth() / 2));
            const tooltipID      = $(this).attr('id');
            let $tooltip         = $('<div class="c-tooltip__tooltip js-tooltip__tooltip" aria-describedby="' + tooltipID + '" tabindex="3"</div>').html(tooltipText);
            const $otherTooltips = $('.js-tooltip__tooltip').not($tooltip);

            // Add a new tooltip
            $(this).after($tooltip);

            // Don't let the Tooltip go off the screen on the left
            let tooltipLeftPos = tooltipCenter - ($tooltip.outerWidth() / 2);

            if (tooltipLeftPos < 12) {
                tooltipLeftPos = 12;
            }

            // Remove other tooltips
            $otherTooltips.fadeOut(300, 'linear', function () {
                $(this).remove();
            });
            $tooltipBtns.not($(this)).removeClass('tooltip-open');

            // Top position + arrow size
            $tooltip.css('top', tooltipTop + 20);
            // Tooltip left position, centred to tooltip size
            $tooltip.css('left', tooltipLeftPos);
            // Fade in the tooltip
            $tooltip.fadeIn(200);
        }
    });

    // Keyboard functionality for tooltips
    $tooltipBtns.on('keyup', function (e) {

        // Open when 'Enter' is pressed
        if (e.which == 13) {
            e.stopPropagation();
            $(this).click();
        }
        // Close when 'Esc' is pressed
        if (e.which == 27) {
            $(this).next('.js-tooltip__tooltip').fadeOut(300, 'linear', function () {
                $(this).remove();
            });
            $(this).removeClass('tooltip-open');
        }
    });

    /**
     * Add tabindex to question tooltips
     */
    const $questionText = $('.js-question-text');

    // The tabindex of 3 is to accomodate for the 'Start Again' and 'Back' buttons,
    // which have tabindexes of 1 and 2 set.
    $questionText.each(function (i) {
        $(this).find('a').attr('tabindex', i + 3);
    });
    
    /**
     * Close Tooltip on click anywhere else
     */
    $("body").click(function (e) {
        const $clickedElem = $(e.target);

        if (!$clickedElem.hasClass('js-tooltip__tooltip') && $clickedElem.closest('.js-tooltip__tooltip').length === 0) {
            hideTooltips();
        }
    });

    function hideTooltips () {
        // Remove other tooltips
        $('.js-tooltip__tooltip').fadeOut(300, 'linear', function () {
            $(this).remove();
        });

        $tooltipBtns.removeClass('tooltip-open');
    }

    /**
     * Google Analytics event tracking
     */
    const $nextButton      = $('.js-next');
    const $question        = $('.js-question-container');
    const $countrySelector = $('.js-country-selector');

    $nextButton.on('click', function (e) {
        const questionText = $question.find('.js-question-text').text();
        let answerText     = $question.find('.js-question-option:checked').next('label').text();
        let alert;
        if ($countrySelector.length) {
            answerText = $('.js-country-selector.ui-autocomplete-input').val();
        }
        // Check an answer has been selected.
        if (answerText.length === 0) {
            $('.js-alert p').remove();

            alert = true;

            if (alert) {
                $('<p role="alert"><strong>Please select an answer to continue.</strong></p>').appendTo('.js-alert');
            } else {
                return false;
            }
        } else {
            trackAnalyticEvent('Question', questionText, answerText);
        }
    });

    // Remove the alert once a selection has been made
    $('.js-question-option').on('click', function () {
        $('.js-alert p').slideUp("normal", function () {
            $(this).remove();
        });
    });

    $('.c-country-dropdown').on('click', function () {
        $(this).on('change', function () {
            $('.js-alert p').remove();
        });
    });

    const $startButton = $('.js-start');

    $startButton.on('click', function (e) {
        trackAnalyticEvent('Start', 'Form started', null);
    });

    $(window).on('load', function () {
        if ($('.js-main-wrapper').hasClass('js-is-conclusion')) {
            trackAnalyticEvent('Conclusion', 'Conclusion reached', null);
        }
    }).on('resize', function () {
        hideTooltips();
    });

    /**
     * Send email ajax functionality
     */
    const $ajaxEmailForm = $('.js-email-ajax-form');

    $ajaxEmailForm.submit(function (e) {
        // Stop the browser from submitting the form.
        e.preventDefault();

        // Serialize the form data.
        const formData = $(this).serialize();

        const $formContainer = $(this).closest('.js-email-ajax-component');
        const $formMessage   = $formContainer.find('.js-email-ajax-message');

        // Submit the form using AJAX.
        $.ajax({
            type : 'POST',
            url  : $(this).attr('action'),
            data : formData
        }).done(function (response) {
            // Make sure that the formMessages div has the 'success' class.
            $formMessage.removeClass('error').addClass('success');

            // Set the message text.
            $formMessage.text(response);
        }).fail(function (data) {
            // Make sure that the formMessages div has the 'error' class.
            $formMessage.removeClass('success').addClass('error');

            // Set the message text.
            if (data.responseText !== '') {
                $formMessage.html(data.responseText);
            } else {
                $formMessage.text('Oops! An error occurred and your message could not be sent.');
            }
        });
    });

    /**
     * Init country selector drop down
     */
    $countrySelector.selectToAutocomplete();

})(jQuery);
